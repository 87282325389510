.ember-light-table-wrapper {
  width: 100%;
  overflow-x: scroll;
}

.ember-light-table {
  .lt-head table {
    margin-bottom: 0;
  }

  .lt-body-wrap {
    overflow-y: auto;
  }

  .lt-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .total-cell {
    text-align: right;
  }
}

.ace-datetimepicker {
  display: block;
}

.tags-field input {
  padding-left: 10px !important
}
