@import 'ember-bootstrap/bootstrap';
@import 'ace';
@import 'ace-fonts';
@import 'ace-skins';
@import 'ace-ie';
@import 'ember-power-select';
@import 'servir-ember-utilities';

.btn-group > .btn-xs {
    border-width: 3px;
}

.user-info {
    max-width: 150px;
}

// Fix ace-power-select z-index on modal.
.ember-basic-dropdown-content {
    z-index: 10000;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.report-card {
    padding: 10px 20px;
    text-decoration: none !important;
    cursor: pointer;
}

.selected-button {
    color: #FFF;
    background-color: #69AA46
}
